.custom-div-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: white;
  }
  
  .profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  