/* Home.css */

/* Conteneur de l'icône personnalisée */
.custom-marker {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Rendre les marqueurs circulaires */
    overflow: hidden; /* Assurer que l'image ne déborde pas */
    border: 4px solid; /* Bordure de 4px pour les couleurs */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Image de profil ou icône par défaut */
  .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Assurer que l'image s'ajuste correctement */
    border-radius: 50%;
  }
  